<template>
	<div class="login-page">
		<div class="container center">
			<div class="row">
				<div class="col-12 col-sm-9 col-md-7 col-lg-5 m-auto pt-5">
					<div class="card">
						<div class="card-header bg-white">
							<h3 class="text-center mb-0 text-secondary mt-2">
								{{$conf.logo}}
							</h3>
						</div>
						<div class="card-body">
							<el-form ref="ruleForm" :rules="rules" :model="form" class="form">
								<el-form-item prop="user_name">
									<el-input prefix-icon="el-icon-user-solid" @keyup.enter.native="submit" v-model="form.user_name" size="medium" placeholder="请输入用户名"></el-input>
								</el-form-item>
								<el-form-item prop="password">
									<el-input prefix-icon="el-icon-lock" v-model="form.password" size="medium" type="password" placeholder="请输入密码" @keyup.enter.native="submit"></el-input>
								</el-form-item>
								<el-form-item>
									<el-button type="danger" size="medium" class="w-100 mt-3" @click="submit" :loading="loading">
										{{loading? '登录中...' : '立即登录'}}
									</el-button>
								</el-form-item>
							</el-form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	let ms = []
	import {
		mapGetters
	} from 'vuex'
	export default {
		data() {
			return {
				loading: false,
				form: {
					user_name: "",
					password: ""
				},
				rules: {
					user_name: [{
						required: true,
						message: "请输入用户名",
						trigger: 'blur'
					}],
					password: [{
						required: true,
						message: "请输入密码",
						trigger: 'blur'
					}]
				}
			}
		},
		computed: {
			...mapGetters([
				'adminIndex'
			])
		},


		methods: {
			submit() {
				this.$refs.ruleForm.validate((e) => {
					if (!e) return;
					// 提交表单
					this.loading = true
					this.axios({
						params: {
							s: 'store/passport/login',
						},
						data: {
							User: this.form
						},
						method: 'post',
					}).then(res => {
						this.$message(res.data.msg);
						if (res.data.code == 1) {
							// 登录成功
							// 存储到vuex
							let data = res.data.data
							// 存储到本地存储
							this.$store.commit('login', data)
							// 存储权限规则
							if (data.role && data.role.rules) {
								window.sessionStorage.setItem('rules', JSON.stringify(data.role.rules))
							}
							// 生成后台菜单
							this.$store.commit('createNavBar', data.menus)
							// 成功提示
							this.loading = false
							// 跳转后台首页
							this.$router.push({
								name: this.adminIndex
							})
						}
						this.loading = false
					}).catch(err => {
						this.loading = false
					})
				})
			}
		},
	}
</script>

<style scoped>
	.login-page {
		position: fixed;
		top: 0;
		left: 0;
		z-index: -1;
		width: 100%;
		height: 100%;
		content: '';
		background: #62a8ea;
		background: url(../../assets/images/login_bg.png) center center/cover no-repeat !important;
	}

	.card-header {
		text-align: center;
		border: none;
	}

	.card-header img {
		width: 3.125rem;
		height: 3.125rem;
	}

	.center {
		position: relative;
		top: 50%;
		/*偏移*/
		transform: translateY(-55%);
	}

	.form {
		padding: 0 2rem 3.125rem;
	}

	.login-page>>>.el-form-item {
		margin: 2rem 0;
	}

	.login-page>>>.el-button--medium {
		padding: 1rem 1.25rem;
		font-size: 1rem;
		border-radius: 0.25rem;
		width: 80%;
	}

	
	.el-button--danger {
	    color: #FFFFFF;
	    background-color: #fb6638;
	    border-color: #fb6638;
	}
</style>
